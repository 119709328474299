import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import "./login.css";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import kpmlogo from "./../../Assets/kpmLogo.png";
import cslogo from "./../../Assets/corestratWhite 1.png";
import { useSelector, useDispatch } from "react-redux";
import { InitiateResetPassword, LoginUser, LoginUserOtp, ResetPasswordApi, VerifyResetPasswordToken } from "./../Common/apiCalls";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import debounce from "lodash.debounce";
import ReusableSnackbar from "../Common/timeoutSnackbar";

export default function ResetPassword () {

    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get("resetToken");

    const [credentials, setCredentials] = useState({});

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [resetTokenValidationError, setResetTokenValidationError] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    useEffect(async () => {
        if (resetToken != null || resetToken != undefined) {
          // Validate Reset Token and get UserId
          try {
            const apiResponse = await VerifyResetPasswordToken(resetToken);
            if (apiResponse?.status == 200) {
                setResetTokenValidationError(null);
            } else {
                setResetTokenValidationError(apiResponse?.data?.message);
            }
          } catch (error) {
            console.log(error);
            setResetTokenValidationError(error.response?.data?.message);
          }
        } 

    }, [])
  
    const closeSnackbar = () => {
        setShowSnackbar(false);
    }

    const handleClickSendResetPasswordMailButton = async () => {
        try {
            const apiResponse = await ResetPasswordApi(resetToken, credentials.new_password);
            setResetTokenValidationError(apiResponse?.data?.message);
        } catch (error) {
            setResetTokenValidationError("Could not reset password");
        }
    }

    const handleInputChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
        if (e.target.name == "new_password") {
          const passwordRegex = /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/;
          if (passwordRegex.test(e.target.value)) {
            setPasswordError(false);
          } else {
            setPasswordError(true);
          }
        }

        if (e.target.name == "confirm_new_password") {
            if (e.target.value == credentials.new_password) {
                setConfirmPasswordError(false);
            } else {
                setConfirmPasswordError(true);
            }
        }
      };

    const handleClickGoToLoginButton = () => {
        window.location.href = "/"
    }

    return (

        <>
        <div className="triangle"></div>
        <Grid
            container
            spacing={4}
            sx={{ pl: 1, pr: 1 }}
            style={{ height: "104vh" }}
        >
            <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{
                background: "#184A90",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
            <img
                src={kpmlogo}
                style={{ margin: "1%", width: "25%" }}
                alt="Logo"
            />
            <Typography
                style={{
                fontWeight: "400",
                color: "#FFFFFF", // White text color
                fontSize: "1.4rem", // Adjust the font size to match h5
                marginTop: "10px",
                marginBottom: "10px",
                }}
            >
                Welcome Back to
            </Typography>
            <Typography
                variant="h4"
                style={{
                fontWeight: "600",
                color: "#FFFFFF", // White text color
                marginBottom: "40px",
                }}
            >
                Digital Lending Automation
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                style={{
                    fontWeight: "600",
                    color: "#FFFFFF", // White text color
                    fontSize: "1.0rem", // Adjust the font size to match h5
                }}
                >
                Powered by
                </Typography>
                <img
                src={cslogo}
                style={{ margin: "5%", width: "15%" }}
                alt="Logo"
                />
                <Typography
                style={{
                    fontWeight: "600",
                    color: "#FFFFFF", // White text color
                    fontSize: "1.0rem", // Adjust the font size to match h5
                }}
                >
                Corestrat
                </Typography>
            </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}
            style={{
                background: "#215DB0",
                height: "fit-content",
                maxHeight: "110%",
                minHeight: "100%",
            }}
            >
            {resetTokenValidationError == null ? (
                <Box style={{ margin: "30%" }}>
                <Box style={{ marginTop: "5%", color: "#ffffff" }}>
                <Typography variant="body2">Enter new password</Typography>
                </Box>
                <TextField
                fullWidth
                variant="outlined"
                type="password"
                value={credentials.new_password}
                name="new_password"
                InputProps={{
                    style: {
                    backgroundColor: "white", // Set the background color to white
                    },
                }}
                FormHelperTextProps={{ sx: { color: '#ff2233' } }}
                onChange={(e) => handleInputChange(e)}
                helperText={passwordError ? "Password should contain atleast one number" : null}
                />

                <Box style={{ marginTop: "5%", color: "#ffffff" }}>
                <Typography variant="body2">Confirm new password</Typography>
                </Box>
                <TextField
                fullWidth
                variant="outlined"
                type="password"
                value={credentials.confirm_new_password}
                name="confirm_new_password"
                InputProps={{
                    style: {
                    backgroundColor: "white", // Set the background color to white
                    },
                }}
                FormHelperTextProps={{ sx: { color: '#ff2233' } }}
                onChange={(e) => handleInputChange(e)}
                helperText={confirmPasswordError ? "Passwords don't match" : null}
                />

                <Box textAlign="center" style={{ width: "100%" }}>
                <Button
                    sx={{ mt: 5 }}
                    size="large"
                    variant="contained"
                    style={{
                    border: "2.39px solid #FFFFFF",
                    backgroundColor: "#C87619",
                    color: "white",
                    width: "90%",
                    }}
                    onClick={handleClickSendResetPasswordMailButton}
                >
                    Reset Password
                </Button>
                </Box>
                </Box>
            ) : (
            <Box style={{ margin: "30%" }}>
                <Box style={{ marginTop: "5%", color: "#ffffff", justifyContent: "center" }}>
                    <Typography variant="h6">{resetTokenValidationError}</Typography>
                </Box>
                <Box textAlign="center" style={{ width: "100%" }}>
                <Button
                    sx={{ mt: 5 }}
                    size="large"
                    variant="contained"
                    style={{
                    border: "2.39px solid #FFFFFF",
                    backgroundColor: "#C87619",
                    color: "white",
                    width: "90%",
                    }}
                    onClick={handleClickGoToLoginButton}
                >
                    Go To Login
                </Button>
                </Box>
            </Box>
            )}
            
            </Grid>
            <ReusableSnackbar 
            open={showSnackbar}
            onClose={closeSnackbar}
            message={alertMessage}
            severity={alertSeverity}
            />
        </Grid>
        </>
    )
}