import Axios from "axios";
import { base_url } from "./../../constants";
//------------------------Login API --------------------------------//
export const LoginUser = async (data) => {
  try {
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
    };
    const finaldata = {
        user_id: data.email,
        user_password: data.password,
    };
    const response = await Axios.post(`${base_url}/api/v1/users/login`, finaldata , config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

//------------------------Login OTP API --------------------------------//
export const LoginUserOtp = async (data,otp) => {
  try {
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
    };
    const finaldata = {
        user_id: data.email,
        otp: otp,
    };
    const response = await Axios.post(`${base_url}/api/v1/users/verifyLogin`, finaldata , config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

//----------------------------- Initiate Reset Password API ---------------------------//
export const InitiateResetPassword = async (userId, currentPassword) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const requestBody = {user_id: userId, current_password: currentPassword}
    const response = await Axios.post(`${base_url}/api/v2/webapp/initiateResetPassword`, requestBody, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
}


//----------------------------- Validate Reset Password Token API ---------------------------//
export const VerifyResetPasswordToken = async (resetToken) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const response = await Axios.get(`${base_url}/api/v2/webapp/verifyResetPasswordToken?resetToken=${resetToken}`, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
}

//----------------------------- Reset Password API ---------------------------//
export const ResetPasswordApi = async (resetPasswordToken, newPassword) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const requestBody = {reset_password_token: resetPasswordToken, new_password: newPassword}
    const response = await Axios.post(`${base_url}/api/v2/webapp/resetPassword`, requestBody, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
}