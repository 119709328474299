import React from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { useState, useEffect } from "react";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import ErrorSnackbar from "../Common/errorSnackbar";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { LoanDetailsById, PendingLoans } from "../../redux/actions/LoanActions";
import AppBar from "@mui/material/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import SideNav from "../Common/sideNav";
import Headerkpm from "../Common/header";

const PendingLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isloanAgreement, setisloanAgreement] = React.useState(false);
  const [loansfetched, setloansfetched] = React.useState(false);
  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const handleagreeclick = () => {
    setisloanAgreement(true);
  };

  const loanPendingLoading = useSelector(
    (state) => state.applicationReducer.loanPendingLoading
  );

  const loanPendingSuccess = useSelector(
    (state) => state.applicationReducer.loanPendingSuccess
  );

  const loanPendingFail = useSelector(
    (state) => state.applicationReducer.loanPendingFail
  );

  useEffect(() => {
    if (loanPendingSuccess.data != undefined) {
      setloansfetched(true);
      console.log(loanPendingSuccess);
    }
  }, [loanPendingSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(PendingLoans(handleLogout));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleverifyclick = (loanid) => {
    // const fetchData = async () => {
    //   try {
    //      await dispatch(LoanDetailsById(loanid));
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    // fetchData();
    if (localStorage.getItem("Role") == "QDEM") {
      navigate("/pendingActionsQDEM", { state: { id: loanid } });
    } else if (localStorage.getItem("Role") == "QDEA") {
      navigate("/pendingActionsQDEA", { state: { id: loanid } });
    } else {
      navigate("/pendingActions", { state: { id: loanid } });
    }
  };

  return (
    <>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        {/* Header */}
        <Grid item xs={12}>
          <CssBaseline />
          <AppBar position="fixed">
            <Headerkpm />
          </AppBar>
        </Grid>
        {/* Main content area */}
        <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
          {/* SideNav */}
          <Grid item xs={3}>
            <SideNav />
          </Grid>
          {/* Main content */}
          {loansfetched ? (
            <Grid item xs={9} sx={{ padding: 1.5, marginTop: "64px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Pending Actions
              </Typography>
              {loanPendingSuccess?.data?.map((loan) => (
                <Box
                  key={loan.loan_id}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "4px solid blue",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    gutterBottom
                  >
                    {`Loan ${loan.loan_reference_id}`}
                  </Typography>
                  <Typography sx={{ fontWeight: "20px" }} gutterBottom>{`Customer Name: ${loan?.customer_name}`}</Typography>
                <Typography sx={{ fontWeight: "20px" }} gutterBottom>{`Branch Name: ${loan?.branch_name}`}</Typography>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Application Details
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Product: ${loan.loan_product}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Amount: ${loan.loan_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Tenure: ${loan.loan_tenure}`}</Typography>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Approval Details
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Eligibility Amount: ${loan.eligibility_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Interest Rate: ${loan.interest_rate}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Tenure: ${loan.tenure}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Sanctioned Amount: ${loan.sanctioned_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Approval Status: ${loan.loan_approval_status}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Declined Reason: ${loan.declined_reason}`}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "auto",
                    }}
                  >
                    <Button
                      variant="Contained"
                      onClick={() => handleverifyclick(loan.loan_id)}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#C87619",
                        color: "white",
                      }}
                    >
                      Verify
                    </Button>
                  </Box>
                </Box>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          ;
        </Grid>
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ErrorSnackbar open={loanPendingFail} onClose={closeSnackbar} />
    </>
  );
};

export default PendingLanding;
