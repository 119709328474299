import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import "./login.css";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import kpmlogo from "./../../Assets/kpmLogo.png";
import cslogo from "./../../Assets/corestratWhite 1.png";
import { useSelector, useDispatch } from "react-redux";
import { InitiateResetPassword, LoginUser, LoginUserOtp } from "./../Common/apiCalls";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import debounce from "lodash.debounce";
import ReusableSnackbar from "../Common/timeoutSnackbar";

export default function Login() {
  //Initializing the variables
  const [islogin, setislogin] = React.useState(true);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [isSuccessfullyLogin, setisSuccessfullyLogin] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isResetMailSent, setIsResetMailSent] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [showSnackbar, setShowSnackbar] = useState(false);
  
  const closeSnackbar = () => {
    setShowSnackbar(false);
  }

  const dispatch = useDispatch();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const navigate = useNavigate();
  //Function Calls

  const LoginSignup = async () => {
    try {
      setisLoading(true);
      setisSuccessfullyLogin(true);
      setOTPerror(false);
      setOtp("");
      const results = await LoginUser(credentials);
      if (results.data.code == 200) {
        setIsOtpSent(true);
        setisLoading(false);
      } else {
        setisSuccessfullyLogin(false);
        setIsOtpSent(false);
        setisLoading(false);
      }
      //   console.log(results);
    } catch (error) {
      console.log("Error", error);
      if (error.response == 401) {
        setisSuccessfullyLogin(false);
        setIsOtpSent(false);
        setisLoading(false);
      }
    }
  };

  const verifyOTP = async () => {
    try {
      const results = await LoginUserOtp(credentials, otp);
      if (results.data.code == 200) {
        setOTPerror(false);
        setOtpErrorMessage("");
        dispatch({
          type: ActionTypes.LOGIN_DETAILS_SUCCESS,
          payload: results.data.data,
        });
        localStorage.clear();
        localStorage.setItem("jwt_token", results.data.data.auth_token);
        localStorage.setItem("Role", results.data.data.user_role_code);
        localStorage.setItem("Email", credentials.email);
        localStorage.setItem("employee_code", results.data.data.employee_code);
        if (localStorage.getItem("Role") == "QDE") {
          navigate("/LoanDetailsQDE");
        } else if (localStorage.getItem("Role") == "BM") {
          navigate("/LoanDetailsBM");
        } else if (localStorage.getItem("Role") == "HO") {
          navigate("/LoanDetailsHO");
        } else if (localStorage.getItem("Role") == "QDEM") {
          navigate("/LoanDetailsQDEM");
        } else if (localStorage.getItem("Role") == "QDEA") {
          navigate("/LoanDetailsQDEM");
        } else {
          navigate("/Invalid");
        }
      } else {
        setOTPerror(true);
      }
      //   console.log(results);
    } catch (error) {
      console.log("Error", error);
      setOTPerror(true);
    }
  };

  const handleOtherAccount = () => {
    setIsOtpSent(false);
  };

  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    if (e.target.name == "email") {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(e.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  };

  const handleClickResetPasswordButton = () => {
    setIsResetPassword(true);
  }

  const handleClickSendResetPasswordMailButton = async () => {
    try {
      const apiResponse = await InitiateResetPassword(credentials.reset_email, credentials.reset_password);
      if (apiResponse.status == 200 && apiResponse?.data?.code == 200) {
        setAlertMessage("Reset Link sent to Email");
        setAlertSeverity("success");
        setShowSnackbar(true);
        const timer = setTimeout(() => {window.location.href = "/"}, 3000);
      } else {
        setAlertMessage("Invalid Credentials");
        setAlertSeverity("error");
        setShowSnackbar(true);
      }
    } catch (error) {
      setAlertMessage("Internal Server Error");
      setAlertSeverity("error");
      setShowSnackbar(true);
    }
    
  }

  if (isResetPassword) {
    return (<>
      <div className="triangle"></div>
      <Grid
        container
        spacing={4}
        sx={{ pl: 1, pr: 1 }}
        style={{ height: "104vh" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            background: "#184A90",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={kpmlogo}
            style={{ margin: "1%", width: "25%" }}
            alt="Logo"
          />
          <Typography
            style={{
              fontWeight: "400",
              color: "#FFFFFF", // White text color
              fontSize: "1.4rem", // Adjust the font size to match h5
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Welcome Back to
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontWeight: "600",
              color: "#FFFFFF", // White text color
              marginBottom: "40px",
            }}
          >
            Digital Lending Automation
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontWeight: "600",
                color: "#FFFFFF", // White text color
                fontSize: "1.0rem", // Adjust the font size to match h5
              }}
            >
              Powered by
            </Typography>
            <img
              src={cslogo}
              style={{ margin: "5%", width: "15%" }}
              alt="Logo"
            />
            <Typography
              style={{
                fontWeight: "600",
                color: "#FFFFFF", // White text color
                fontSize: "1.0rem", // Adjust the font size to match h5
              }}
            >
              Corestrat
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}
          style={{
            background: "#215DB0",
            height: "fit-content",
            maxHeight: "110%",
            minHeight: "100%",
          }}
        >
          <Box style={{ margin: "30%" }}>
            <Box style={{ color: "#ffffff" }}>
              <Typography variant="body2">Enter User ID</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              error={emailError}
              value={credentials.reset_email}
              name="reset_email"
              InputProps={{
                style: {
                  backgroundColor: "white", // Set the background color to white
                },
              }}
              onChange={(e) => handleInputChange(e)}
              helperText={emailError ? "User Id is Invalid." : null}
            />

            <Box style={{ marginTop: "5%", color: "#ffffff" }}>
              <Typography variant="body2">Enter current password</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              value={credentials.reset_password}
              name="reset_password"
              InputProps={{
                style: {
                  backgroundColor: "white", // Set the background color to white
                },
              }}
              onChange={(e) => handleInputChange(e)}
            />

            <Box textAlign="center" style={{ width: "100%" }}>
              <Button
                sx={{ mt: 5 }}
                size="large"
                variant="contained"
                style={{
                  border: "2.39px solid #FFFFFF",
                  backgroundColor: "#C87619",
                  color: "white",
                  width: "90%",
                }}
                onClick={handleClickSendResetPasswordMailButton}
              >
                Reset Password
              </Button>
            </Box>
            
          </Box>
        </Grid>
        <ReusableSnackbar 
        open={showSnackbar}
        onClose={closeSnackbar}
        message={alertMessage}
        severity={alertSeverity}
        />
      </Grid>
      </>
        )
  }

  return (
    <>
      <div className="triangle"></div>
      <Grid
        container
        spacing={4}
        sx={{ pl: 1, pr: 1 }}
        style={{ height: "104vh" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            background: "#184A90",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={kpmlogo}
            style={{ margin: "1%", width: "25%" }}
            alt="Logo"
          />
          <Typography
            style={{
              fontWeight: "400",
              color: "#FFFFFF", // White text color
              fontSize: "1.4rem", // Adjust the font size to match h5
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Welcome Back to
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontWeight: "600",
              color: "#FFFFFF", // White text color
              marginBottom: "40px",
            }}
          >
            Digital Lending Automation
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontWeight: "600",
                color: "#FFFFFF", // White text color
                fontSize: "1.0rem", // Adjust the font size to match h5
              }}
            >
              Powered by
            </Typography>
            <img
              src={cslogo}
              style={{ margin: "5%", width: "15%" }}
              alt="Logo"
            />
            <Typography
              style={{
                fontWeight: "600",
                color: "#FFFFFF", // White text color
                fontSize: "1.0rem", // Adjust the font size to match h5
              }}
            >
              Corestrat
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            background: "#215DB0",
            //   background:
            //     "linear-gradient(195.28deg, #0093FF 6.07%, #043152 89.27%)",
            height: "fit-content",
            maxHeight: "110%",
            minHeight: "100%",
          }}
        >
          {islogin ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mt: 10 }}>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "600",
                      color: "#ffffff",
                      WebkitBackgroundClip: "text", // For webkit browsers like Chrome
                      marginTop: "120px",
                      // Make the text color transparent
                    }}
                  >
                    LOGIN
                  </Typography>
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid style={{ width: "50%" }}>
                  <Box
                    textAlign="center"
                    style={{ margin: "10%", color: "#ffffff" }}
                  >
                    {/* <Typography variant="body2">
                    Don't have an account?{" "}
                    <Link
                      style={{ color: "#ffffff" }}
                      onClick={(e) => setLogin(false, e)}
                    >
                      Register here
                    </Link>
                  </Typography> */}
                  </Box>
                  {!isSuccessfullyLogin ? (
                    <Box style={{ margin: "5%" }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          ml: 5,
                          ml: 0,
                        }}
                      >
                        <Alert severity="error">
                          Sorry! Either the password or username is incorrect.
                        </Alert>
                      </Grid>
                    </Box>
                  ) : null}
                  {isLoading ? (
                    <Box style={{ margin: "5%" }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          ml: 18,
                          mt: 10,
                        }}
                      >
                        <CircularProgress style={{ color: "#FFFFFF" }} />
                      </Grid>
                    </Box>
                  ) : isOtpSent ? (
                    <>
                      {OTPerror ? (
                        <Box style={{ margin: "5%" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              ml: 5,
                              ml: 0,
                            }}
                          >
                            <Alert severity="error">OTP is incorrect.</Alert>
                          </Grid>
                        </Box>
                      ) : null}
                      <Box style={{ margin: "5%" }}>
                        <Box style={{ color: "#ffffff" }}>
                          <Typography variant="body2">OTP</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={OTPerror}
                          value={otp}
                          name="otp"
                          InputProps={{
                            style: {
                              backgroundColor: "white", // Set the background color to white
                            },
                          }}
                          onChange={(e) => handleOtpChange(e.target.value)}
                          helperText={OTPerror ? "OTP is Invalid." : null}
                        />
                      </Box>
                      <Box textAlign="center" style={{ width: "100%" }}>
                        <Button
                          sx={{ mt: 5 }}
                          size="large"
                          variant="contained"
                          style={{
                            border: "2.39px solid #FFFFFF",
                            backgroundColor: "#C87619",
                            color: "white",
                            width: "90%",
                          }}
                          onClick={verifyOTP}
                        >
                          Verify OTP
                        </Button>
                      </Box>
                      <Box textAlign="center" style={{ width: "100%" }}>
                        <Button
                          sx={{ mt: 5 }}
                          size="large"
                          variant="outlined"
                          style={{
                            border: "2.39px solid #FFFFFF",
                            backgroundColor: "#C87619",
                            color: "white",
                            width: "90%",
                          }}
                          onClick={handleOtherAccount}
                        >
                          Login with another Account
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ margin: "5%" }}>
                        <Box style={{ color: "#ffffff" }}>
                          <Typography variant="body2">User ID</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={emailError}
                          value={credentials.email}
                          name="email"
                          InputProps={{
                            style: {
                              backgroundColor: "white", // Set the background color to white
                            },
                          }}
                          onChange={(e) => handleInputChange(e)}
                          helperText={emailError ? "User Id is Invalid." : null}
                        />
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <Box style={{ color: "#ffffff" }}>
                          <Typography variant="body2">Password</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          type="password"
                          variant="outlined"
                          name="password"
                          value={credentials.password}
                          InputProps={{
                            style: {
                              backgroundColor: "white", // Set the background color to white
                            },
                          }}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Box>
                      <Box textAlign="center" style={{ width: "100%" }}>
                        <Button
                          sx={{ mt: 5 }}
                          size="large"
                          variant="contained"
                          style={{
                            border: "2.39px solid #FFFFFF",
                            backgroundColor: "#C87619",
                            color: "white",
                            width: "90%",
                          }}
                          onClick={LoginSignup}
                        >
                          Login
                        </Button>
                      </Box>
                      <Box sx={{margin: "5%"}}><a style={{color: "white"}} href="#" onClick={handleClickResetPasswordButton}>Reset Password</a></Box>
                    </>
                  )}
                </Grid>
              </div>
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
