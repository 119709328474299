import React, { useEffect, useState } from "react";
import "./imageViewer.css";

const ImageViewer = ({ imageSrc }) => {
    const [srcString, setSrcString] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);

    const handleZoomIn = () => setScale(prev => prev * 1.2);
    const handleZoomOut = () => setScale(prev => prev / 1.2);
    const handleRotate = () => setRotation(prev => (prev + 90) % 360);

    const handleClose = () => {
        setIsOpen(false);
        setScale(1);
        setRotation(0);
    }

    useEffect(() => {
        if (imageSrc != null || imageSrc != undefined){
            if (imageSrc?.startsWith("blobUrl:///")) {
                imageSrc = imageSrc.replace("blobUrl:///", "")
            }
            if (imageSrc?.startsWith("http://") || imageSrc?.startsWith("https://")) {
                imageSrc = imageSrc
            } else {
                imageSrc = `data:image/jpeg;base64,${imageSrc}`
            }
        } else {
            imageSrc = "";
        }
        
        setSrcString(imageSrc);
    }, [])

    return (
        <div>
            <img src={srcString} alt="not found" style={{cursor: "pointer", width: "100px", margin: "10px"}}
            onClick={() => setIsOpen(true)}/>

            {isOpen && (
                <div className="image-overlay">
                     <button className="image-close-button" onClick={handleClose}>&times;</button>
                    <div className="image-container">
                        <img src={srcString} alt="not found" 
                            style={{
                                transform: `scale(${scale}) rotate(${rotation}deg)`,
                                transition: 'transform 0.2s ease', 
                                maxHeight: '80vh',
                                maxWidth: '80vw',}}
                        />
                        <div className="image-controls">
                            <button onClick={handleZoomIn}>Zoom In</button>
                            <button onClick={handleZoomOut}>Zoom Out</button>
                            <button onClick={handleRotate}>Rotate</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageViewer;