import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LoanDetailsById,
  LoanVerification,
  LoanDetailsByIdv2,
  UpdateBankDetails,
  UploadPhotoVerification,
  UpdateVerificationItemResponse,
} from "../../redux/actions/LoanActions";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import SideNav from "../Common/sideNav";
import Headerkpm from "../Common/header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorSnackbar from "../Common/errorSnackbar";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextField, CircularProgress, Tooltip } from "@mui/material";
import { Gallery, Item } from "react-photoswipe-gallery";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import "photoswipe/dist/photoswipe.css";
import ImageViewer from "../Common/ImageViewer/imageViewer";

import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PendingActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const loanid = state?.id;
  const reference_id = state?.referenceId;
  const [applicationDetails, setapplicationDetails] = useState([]);
  const [verificationDetails, setverificationDetails] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab2, setSelectedTab2] = useState(0);
  const [detailsSet, setdetailsSet] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [nestedKeys, setnestedKeys] = useState([]);
  const [mainKeys, setmainKeys] = useState([
    "relation_code",
    "first_name",
    "last_name",
    "aadhaar_number",
  ]);

  const [bankDetailsUpdated, setBankDetailsUpdated] = useState({});

  const [isBankDetailsEdit, setIsBankDetailsEdit] = useState(false);
  const [isVerificationsEdit, setIsVerificationsEdit] = useState({});

  const role = localStorage.getItem("Role");
  const empcode = localStorage.getItem("employee_code");
  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const base64Prefix = "data:image/jpeg;base64,";

  const loanDetailsByIdSuccess = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdSuccess
  );

  const loanDetailsByIdLoading = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdLoading
  );

  const loanDetailsByIdFail = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdFail
  );

  const loanVerificationSuccess = useSelector(
    (state) => state.applicationReducer.loanVerificationSuccess
  );

  const loanVerificationFail = useSelector(
    (state) => state.applicationReducer.loanVerificationFail
  );

  useEffect(() => {
    if (loanDetailsByIdSuccess.data != undefined) {
      setdetailsSet(true);
      console.log(loanDetailsByIdSuccess.data);
    }
    if (!reference_id?.startsWith("JLG") && loanDetailsByIdSuccess?.data) {
      setapplicationDetails(loanDetailsByIdSuccess?.data?.application_details);
      setverificationDetails(
        loanDetailsByIdSuccess?.data?.verification_details
      );
    }
    if (reference_id?.startsWith("JLG") && loanDetailsByIdSuccess?.data) {
      setapplicationDetails(
        loanDetailsByIdSuccess?.data?.loan_details[0].application_details
      );
      setverificationDetails(
        loanDetailsByIdSuccess?.data?.loan_details[0].verification_details
      );
      if (loanDetailsByIdSuccess?.data) {
        const nestedKeys1 = Object.keys(
          loanDetailsByIdSuccess?.data?.loan_details[0].application_details
            .household_members[0].aadhaar_details
        );
        setnestedKeys(nestedKeys1);
      }
    }
  }, [loanDetailsByIdSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setdetailsSet(false);
        if (!reference_id?.startsWith("JLG")) {
          await dispatch(LoanDetailsById(loanid, handleLogout));
        }
        if (reference_id?.startsWith("JLG")) {
          await dispatch(LoanDetailsByIdv2(loanid, handleLogout));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const openAcceptDialog = () => {
    setIsAcceptOpen(true);
  };

  const handleCloseAccept = () => {
    setIsAcceptOpen(false);
  };

  const openDeclineDialog = () => {
    setIsDeclineOpen(true);
  };

  const handleCloseDecline = () => {
    setIsDeclineOpen(false);
  };

  const handleAcceptClick = async () => {
    try {
      await dispatch(
        LoanVerification(
          role,
          empcode,
          remarks,
          "APPROVED",
          loanid,
          handleLogout
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsAcceptOpen(false);
    if (localStorage.getItem("Role") == "QDE") {
      navigate("/LoanDetailsQDE");
    } else if (localStorage.getItem("Role") == "BM") {
      navigate("/LoanDetailsBM");
    } else if (localStorage.getItem("Role") == "HO") {
      navigate("/LoanDetailsHO");
    } else {
      navigate("/Invalid");
    }
  };

  const handleDeclineClick = async () => {
    try {
      await dispatch(
        LoanVerification(
          role,
          empcode,
          remarks,
          "DECLINED",
          loanid,
          handleLogout
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsDeclineOpen(true);
    if (localStorage.getItem("Role") == "QDE") {
      navigate("/LoanDetailsQDE");
    } else if (localStorage.getItem("Role") == "BM") {
      navigate("/LoanDetailsBM");
    } else if (localStorage.getItem("Role") == "HO") {
      navigate("/LoanDetailsHO");
    } else if (localStorage.getItem("Role") == "QDEM") {
      navigate("/LoanDetailsQDEM");
    } else if (localStorage.getItem("Role") == "QDEA") {
      navigate("/LoanDetailsQDEA");
    } else {
      navigate("/Invalid");
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleTabChange2 = (event, newValue) => {
    setSelectedTab2(newValue);
  };
  const [selectedKyc, setSelectedKyc] = useState(null);

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedKycData = loanDetailsByIdSuccess.data.loan_details.find(
      (item) => item.application_details.kyc.name === selectedName
    );
    setSelectedKyc(selectedKycData.application_details.kyc);
    setapplicationDetails(selectedKycData.application_details);
    setverificationDetails(selectedKycData.verification_details);
    console.log(selectedKycData);
  };
  // Helper function to format key names for display
  const formatLabel = (key) => {
    // Capitalize and replace underscores or hyphens with spaces
    return key
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toUpperCase() : match.toLowerCase()
      );
  };

  const handleEditBankDetails = (event) => {
    setIsBankDetailsEdit(true);
    setBankDetailsUpdated({
      bank_name: applicationDetails?.bank?.bank_name,
      account_number: applicationDetails?.bank?.account_number,
      beneficiary_name: applicationDetails?.bank?.beneficiary_name,
      ifsc: applicationDetails?.bank?.ifsc,
    })
  }

  const handleBankDetailsChange = (event) => {
    let bankDetailsKey = event.target.name;
    setBankDetailsUpdated(prev => ({...prev, [bankDetailsKey]: event.target.value}));
  }

  const handleSaveBankDetails = async (event) => {
    try {
      await dispatch(UpdateBankDetails(loanid, bankDetailsUpdated, handleLogout));
    } catch (error) {
      console.log(error);
    }
    window.location.reload();
  }

  const handleEditVerification = (event, category, item_number) => {
    setIsVerificationsEdit(prev => ({...prev, [category]: {...prev?.[category], [item_number]: true}}))
  }

  const handleUploadFile = async (event, category, item_number, field_label) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '*'; // You can specify accepted file types here
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try{
          const result = await UploadPhotoVerification(file, handleLogout);
          if (result?.data) {
            console.log(category, item_number, field_label, result?.data, verificationDetails);
            // const verificationFieldItem = verificationDetails?.[category]?.find[entry => entry?.item_number == item_number]?.verification_response?.find(entry => entry?.field_label == field_label)
            // verificationFieldItem.field_response = result?.data
            let prevVerificationDetails = {...verificationDetails}
            setverificationDetails({})
            setverificationDetails({...prevVerificationDetails, [category]: [...prevVerificationDetails?.[category].map(entry => entry?.item_number == item_number ? {...entry, verification_response: entry?.verification_response?.map(entry2 => entry2?.field_label == field_label ? {...entry2, field_response: result?.data} : entry2)} : entry) ]});
          }
        } catch (error) {
          console.log(error);
        }
        
      }
      input.remove();
    };
    input.click();
  }

  const handleChangeVerification = async (event, category, item_number, field_label) => {
    let prevVerificationDetails = {...verificationDetails}
    setverificationDetails({})
    setverificationDetails({...prevVerificationDetails, [category]: [...prevVerificationDetails?.[category].map(entry => entry?.item_number == item_number ? {...entry, verification_response: entry?.verification_response?.map(entry2 => entry2?.field_label == field_label ? {...entry2, field_response: event.target.value} : entry2)} : entry) ]});
  }

  const handleClickSaveVerificationItem = async (event, category, item_number) => {
    // save verification item
    try {
      console.log(category, item_number, verificationDetails?.[category]?.find(entry => entry?.item_number == item_number)?.verification_response );
      const result = await dispatch(UpdateVerificationItemResponse(loanid, category, item_number, verificationDetails?.[category]?.find(entry => entry?.item_number == item_number)?.verification_response, handleLogout));
      // if (result) {
      //   window.location.reload();
      // }
    } catch (error) {
      console.log(error);
    }
    window.location.reload();
  }


  return (
    <>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        {/* Header */}
        <Grid item xs={12}>
          <CssBaseline />
          <AppBar position="fixed">
            <Headerkpm />
          </AppBar>
        </Grid>
        <Grid container>
          {loanDetailsByIdSuccess?.data?.loan_details ? (
            <>
              <Grid
                item
                xs={8}
                sx={{
                  marginTop: "94px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></Grid>
              <Grid
                item
                xs={4}
                sx={{
                  marginTop: "94px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="kyc-select-label">Name</InputLabel>
                  <Select
                    labelId="kyc-select-label"
                    id="kyc-select"
                    value={selectedKyc ? selectedKyc.name : ""}
                    onChange={handleChange}
                    label="Name"
                  >
                    {loanDetailsByIdSuccess?.data?.loan_details.map(
                      (item, index) => (
                        <MenuItem
                          key={index}
                          value={item.application_details.kyc.name}
                        >
                          {item.application_details.kyc.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : null}

          {/* Main content area */}
          <Grid item container sx={{ marginTop: "0px", flexGrow: 1 }}>
            {/* SideNav */}
            <Grid item xs={2}>
              <SideNav />
            </Grid>
            {/* Main content */}
            {detailsSet ? (
              <>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    paddingLeft: "10px",
                    marginTop: "84px",
                  }}
                >
                  <Typography variant="h6">Loan Dashboard</Typography>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="tabs"
                    sx={{
                      paddingLeft: "15px",
                      "& .MuiTab-root": {
                        minWidth: "auto",
                        padding: "2px 8px",
                        minHeight: "30px",
                        fontWeight: "bold",
                        fontSize: "10px",
                        backgroundColor: "white", // Default background color for tabs
                        "&.Mui-selected": {
                          backgroundColor: "#184A90", // Blue background color for selected tab
                          color: "white", // Text color for selected tab
                          borderBottom: "none",
                        },
                        "& .Mui-selected.MuiTab-root": {
                          borderBottom: "none", // Remove bottom border for selected tab
                        },
                      },
                    }}
                  >
                    <Tab label="Loan" sx={{ border: "1px solid #184A90" }} />
                    <Tab label="KYC" sx={{ border: "1px solid #184A90" }} />
                    <Tab
                      label="Credit Bureau"
                      sx={{ border: "1px solid #184A90" }}
                    />
                    <Tab label="Bank" sx={{ border: "1px solid #184A90" }} />
                    <Tab
                      label="Co-applicant"
                      sx={{ border: "1px solid #184A90" }}
                    />
                    <Tab
                      label="Guarantor"
                      sx={{ border: "1px solid #184A90" }}
                    />
                    {reference_id?.startsWith("JLG") ? (
                      <Tab
                        label="Household Members"
                        sx={{ border: "1px solid #184A90" }}
                      />
                    ) : null}
                  </Tabs>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    paddingLeft: "20px",
                    marginTop: "84px",
                  }}
                >
                  <Typography variant="h6" sx={{ marginRight: "20px" }}>
                    Verification
                  </Typography>
                  <Tabs
                    value={selectedTab2}
                    onChange={handleTabChange2}
                    aria-label="tabs"
                    sx={{
                      paddingLeft: "100px",
                      "& .MuiTab-root": {
                        minWidth: "auto",
                        padding: "2px 12px",
                        minHeight: "30px",
                        fontWeight: "bold",
                        fontSize: "10px",
                        backgroundColor: "white", // Default background color for tabs
                        "&.Mui-selected": {
                          backgroundColor: "#184A90", // Blue background color for selected tab
                          color: "white", // Text color for selected tab
                          borderBottom: "none",
                        },
                        "& .Mui-selected.MuiTab-root": {
                          borderBottom: "none", // Remove bottom border for selected tab
                        },
                      },
                    }}
                  >
                    <Tab label="KYC" sx={{ border: "1px solid #184A90" }} />
                    <Tab label="House" sx={{ border: "1px solid #184A90" }} />
                    <Tab label="Bank" sx={{ border: "1px solid #184A90" }} />
                    <Tab
                      label="Co-applicant"
                      sx={{ border: "1px solid #184A90" }}
                    />
                    <Tab label="Family" sx={{ border: "1px solid #184A90" }} />
                  </Tabs>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5} sx={{ display: "flex", paddingLeft: "20px" }}>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "20px",
                      marginRight: "40px",
                      width: "600px", // Set desired width
                      height: "450px", // Set desired height
                      borderLeft: "4px solid blue",
                      overflow: "auto",
                    }}
                  >
                    {selectedTab === 0 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Loan Details
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Loan Product:{" "}
                          </span>
                          {applicationDetails?.loan?.loan_product}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Loan Amount:{" "}
                          </span>
                          {applicationDetails?.loan?.applied_loan_amount}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Loan Reference ID:{" "}
                          </span>
                          {applicationDetails?.loan?.loan_reference_id}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Loan Tenure:{" "}
                          </span>
                          {applicationDetails?.loan?.loan_tenure}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Approval Status:{" "}
                          </span>
                          {applicationDetails?.loan?.approval_status}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Approved Tenure:{" "}
                          </span>
                          {applicationDetails?.loan?.approved_tenure}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Center Name:{" "}
                          </span>
                          {applicationDetails?.loan?.center_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Declined Reason:{" "}
                          </span>
                          {applicationDetails?.loan?.declined_reason}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Eligible Amount:{" "}
                          </span>
                          {applicationDetails?.loan?.eligible_amount}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Email ID: </span>
                          {applicationDetails?.loan?.email_id}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Interest Rate:{" "}
                          </span>
                          {applicationDetails?.loan?.interest_rate}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Language: </span>
                          {applicationDetails?.loan?.language}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Phone Number:{" "}
                          </span>
                          {applicationDetails?.loan?.phone_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Sanctioned Amount:{" "}
                          </span>
                          {applicationDetails?.loan?.sanctioned_amount}
                        </Typography>
                      </>
                    )}
                    {selectedTab === 1 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          KYC Details
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Name: </span>
                          {applicationDetails?.kyc?.name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Gender: </span>
                          {applicationDetails?.kyc?.gender}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Aadhaar Number:{" "}
                          </span>
                          {applicationDetails?.kyc?.aadhaar_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>PAN: </span>
                          {applicationDetails?.kyc?.pan_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Voter Id: </span>
                          {
                            loanDetailsByIdSuccess?.data?.application_details
                              ?.kyc?.voter_id
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Phone Number:{" "}
                          </span>
                          {applicationDetails?.kyc?.phone_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Address: </span>
                          {applicationDetails?.kyc?.address}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Marital Status:{" "}
                          </span>
                          {applicationDetails?.kyc?.marital_status}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Onboarded By:{" "}
                          </span>
                          {applicationDetails?.kyc?.onboarded_by}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <ImageViewer
                            imageSrc={applicationDetails?.kyc?.photo}
                          />
                        </Box>
                      </>
                    )}
                    {selectedTab === 2 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Credit Bureau Details
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "34px",
                            fontSize: "19px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Account Summary:{" "}
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "44px",
                            fontSize: "18px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>MFI: </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Max Dpd: </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.max_dpd
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Open Accounts:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.open_accounts
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total High Credit Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_high_credit_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Installment:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_installment
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Outstanding Balance:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_outstanding_balance
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Overdue Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_overdue_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Sanction Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_sanction_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Write off Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.mfi
                              ?.total_write_off_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "44px",
                            fontSize: "18px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Retail: </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Max Dpd: </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.max_dpd
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Open Accounts:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.open_accounts
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total High Credit Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_high_credit_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Installment:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_installment
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Outstanding Balance:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_outstanding_balance
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Overdue Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_overdue_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Sanction Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_sanction_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "58px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Write off Amount:{" "}
                          </span>
                          {
                            applicationDetails?.cb?.account_summary?.retail
                              ?.total_write_off_amount
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "34px",
                            fontSize: "19px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Score: </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "44px",
                            fontSize: "16px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>MFI: </span>
                          {applicationDetails?.cb?.score?.mfi}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "44px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Retail: </span>
                          {applicationDetails?.cb?.score?.retail}
                        </Typography>
                      </>
                    )}
                    {selectedTab === 3 && (
                      <>
                        <div style={{display: "flex"}}>
                          <Typography sx={{ marginRight: "20px", fontSize: "20px", verticalAlign: "center" }}> Bank Details</Typography>
                          <Tooltip title="Edit"><Button onClick={handleEditBankDetails}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                        </div>
                        
                        {isBankDetailsEdit ? 
                        <div style={{marginLeft: "48px", marginTop: "40px", display: "flex"}}>
                          <Typography sx={{fontSize: "16px"}}> <span style={{fontWeight: "bold"}}>Bank Name: </span></Typography>
                          <input style={{fontSize: "16px", marginLeft: "5px"}} name="bank_name" value={bankDetailsUpdated.bank_name} onChange={handleBankDetailsChange}/>
                        </div> : 
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "40px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Bank Name:{" "}
                          </span>
                          {applicationDetails?.bank?.bank_name}
                        </Typography>}


                        {isBankDetailsEdit ? 
                        <div style={{marginLeft: "48px", marginTop: "10px", display: "flex"}}>
                          <Typography sx={{fontSize: "16px"}}> <span style={{fontWeight: "bold"}}>Account Number: </span></Typography>
                          <input style={{fontSize: "16px", marginLeft: "5px"}} name="account_number" value={bankDetailsUpdated.account_number} onChange={handleBankDetailsChange}/>
                        </div> : 
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Account Number:{" "}
                          </span>
                          {applicationDetails?.bank?.account_number}
                        </Typography>}

                        {isBankDetailsEdit ? 
                        <div style={{marginLeft: "48px", marginTop: "10px", display: "flex"}}>
                          <Typography sx={{fontSize: "16px"}}> <span style={{fontWeight: "bold"}}>Beneficiary Name: </span></Typography>
                          <input style={{fontSize: "16px", marginLeft: "5px"}} name="beneficiary_name" value={bankDetailsUpdated.beneficiary_name} onChange={handleBankDetailsChange}/>
                        </div> : 
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Beneficiary Name:{" "}
                          </span>
                          {applicationDetails?.bank?.beneficiary_name}
                        </Typography>}

                        {isBankDetailsEdit ? 
                        <div style={{marginLeft: "48px", marginTop: "10px", display: "flex"}}>
                          <Typography sx={{fontSize: "16px"}}> <span style={{fontWeight: "bold"}}>IFSC: </span></Typography>
                          <input style={{fontSize: "16px", marginLeft: "5px"}} name="ifsc" value={bankDetailsUpdated.ifsc} onChange={handleBankDetailsChange}/>
                        </div> : 
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>IFSC: </span>
                          {applicationDetails?.bank?.ifsc}
                        </Typography>}

                        <Button sx={{marginTop: "40px"}} variant="contained" onClick={handleSaveBankDetails}>Save</Button>
                      </>
                    )}
                    {selectedTab === 4 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Co-applicant Details
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            First Name:{" "}
                          </span>
                          {applicationDetails?.["co-applicant"]?.first_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Last Name:{" "}
                          </span>
                          {applicationDetails?.["co-applicant"]?.last_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Relation: </span>
                          {applicationDetails?.["co-applicant"]?.relation_code}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Aadhaar Number:{" "}
                          </span>
                          {applicationDetails?.["co-applicant"]?.aadhaar_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Aadhaar Details:{" "}
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Name: </span>
                          {
                            applicationDetails?.["co-applicant"]
                              ?.aadhaar_details?.name
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Gender: </span>
                          {
                            applicationDetails?.["co-applicant"]
                              ?.aadhaar_details?.gender
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Date of Birth:{" "}
                          </span>
                          {
                            applicationDetails?.["co-applicant"]
                              ?.aadhaar_details?.dob
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Address: </span>
                          {
                            applicationDetails?.["co-applicant"]
                              ?.aadhaar_details?.address
                          }
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <ImageViewer
                            imageSrc={
                              applicationDetails?.["co-applicant"]
                                ?.aadhaar_details?.photo
                            }
                          />
                        </Box>
                      </>
                    )}
                    {selectedTab === 5 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Guarantor Details
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            First Name:{" "}
                          </span>
                          {applicationDetails?.guarantor?.first_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Last Name:{" "}
                          </span>
                          {applicationDetails?.guarantor?.last_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Relation: </span>
                          {applicationDetails?.guarantor?.relation_code ==
                          "no_value_selected"
                            ? ""
                            : applicationDetails?.guarantor?.relation_code}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Aadhaar Number:{" "}
                          </span>
                          {applicationDetails?.guarantor?.aadhaar_number}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "48px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Aadhaar Details:{" "}
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Name: </span>
                          {applicationDetails?.guarantor?.aadhaar_details?.name}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Date of Birth:{" "}
                          </span>
                          {applicationDetails?.guarantor?.aadhaar_details?.dob}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Gender: </span>
                          {
                            applicationDetails?.guarantor?.aadhaar_details
                              ?.gender
                          }
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "60px",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Address: </span>
                          {
                            applicationDetails?.guarantor?.aadhaar_details
                              ?.address
                          }
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <ImageViewer
                            imageSrc={
                              applicationDetails?.guarantor?.aadhaar_details
                                ?.photo
                            }
                          />
                        </Box>
                      </>
                    )}
                    {selectedTab === 6 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Household Member Details
                        </Typography>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                          {applicationDetails.household_members.map(
                            (member, index) => (
                              <Grid item xs={12} key={index}>
                                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                                  <Typography variant="h6">
                                    Personal Details for Member {index + 1}
                                  </Typography>
                                  {mainKeys.map((key) => (
                                    <Typography key={key} variant="body1">
                                      <strong>{formatLabel(key)}:</strong>{" "}
                                      {member[key]}
                                    </Typography>
                                  ))}

                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 2 }}
                                  >
                                    Aadhaar Details
                                  </Typography>
                                  {nestedKeys.map((key) => (
                                    <Typography key={key} variant="body1">
                                      <strong>{formatLabel(key)}:</strong>{" "}
                                      {member.aadhaar_details[key]}
                                    </Typography>
                                  ))}
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <ImageViewer
                            imageSrc={
                              applicationDetails?.guarantor?.aadhaar_details
                                ?.photo
                            }
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={5} sx={{ display: "flex", paddingLeft: "20px" }}>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "40px",
                      marginRight: "40px",
                      width: "600px", // Set desired width
                      height: "450px", // Set desired height
                      borderLeft: "4px solid blue",
                      overflow: "auto",
                    }}
                  >
                    {selectedTab2 === 0 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Verification
                        </Typography>
                        {verificationDetails?.kyc_verifications?.map(
                          (entry, index) => (
                            <Box key={index} sx={{ marginBottom: "20px" }}>
                              <div style={{display: "flex"}}>
                                <Typography
                                  sx={{
                                    marginLeft: "18px",
                                    fontSize: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {entry?.item}
                                  </span>
                                </Typography>
                                <Tooltip title="Edit"><Button onClick={(event) => handleEditVerification(event, "kyc_verifications", entry?.item_number)}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                                {isVerificationsEdit?.["kyc_verifications"]?.[entry?.item_number] && <Tooltip title="Save"><Button onClick={(event) => handleClickSaveVerificationItem(event, "kyc_verifications", entry?.item_number)}><SaveIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>}
                              </div>
                              
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginLeft: "22px",
                                  alignItems: "center",
                                }}
                              >
                                {entry?.verification_response?.map(
                                  (entry2, index2) => (
                                    <Box
                                      key={index2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {entry2?.field_type === "photo" ? (
                                        <>
                                          <Typography>
                                            {entry2?.field_name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              marginTop: "20px",
                                              marginLeft: "80px",
                                            }}
                                          >
                                            <div style={{display: "flex"}}>
                                              <ImageViewer
                                                imageSrc={entry2?.field_response}
                                              />
                                              {isVerificationsEdit?.["kyc_verifications"]?.[entry?.item_number] && 
                                              <Tooltip title="Upload"><Button onClick={(event) => handleUploadFile(event, "kyc_verifications", entry?.item_number, entry2?.field_label)}>
                                                <FileUploadIcon sx={{width: "20px", height: "20px"}}/>
                                                </Button></Tooltip>}
                                              
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <TextField
                                          label={entry2?.field_name}
                                          variant="outlined"
                                          value={entry2?.field_response}
                                          margin="normal"
                                          disabled={!isVerificationsEdit?.["kyc_verifications"]?.[entry?.item_number]}
                                          onChange={(event) => handleChangeVerification(event, "kyc_verifications", entry?.item_number, entry2?.field_label)}
                                          sx={{ width: "225px" }}
                                        />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    )}
                    {selectedTab2 === 1 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Verification
                        </Typography>
                        {verificationDetails?.house_verifications?.map(
                          (entry, index) => (
                            <Box key={index} sx={{ marginBottom: "20px" }}>
                              <div style={{display: "flex"}}>
                              <Typography
                                sx={{
                                  marginLeft: "48px",
                                  fontSize: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {entry?.item}
                                </span>
                              </Typography>
                              <Tooltip title="Edit"><Button onClick={(event) => handleEditVerification(event, "house_verifications", entry?.item_number)}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                              {isVerificationsEdit?.["house_verifications"]?.[entry?.item_number] && <Tooltip title="Save"><Button onClick={(event) => handleClickSaveVerificationItem(event, "house_verifications", entry?.item_number)}><SaveIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>}
                              </div>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginLeft: "52px",
                                  alignItems: "center",
                                }}
                              >
                                {entry?.verification_response?.map(
                                  (entry2, index2) => (
                                    <Box
                                      key={index2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {entry2?.field_type === "photo" ? (
                                        <>
                                          <Typography>
                                            {entry2?.field_name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              marginTop: "20px",
                                              marginLeft: "80px",
                                            }}
                                          >
                                            <div style={{display: "flex"}}>
                                              <ImageViewer
                                                imageSrc={entry2?.field_response}
                                              />
                                              {isVerificationsEdit?.["house_verifications"]?.[entry?.item_number] && 
                                              <Tooltip title="Upload"><Button onClick={(event) => handleUploadFile(event, "house_verifications", entry?.item_number, entry2?.field_label)}>
                                                <FileUploadIcon sx={{width: "20px", height: "20px"}}/>
                                                </Button></Tooltip>}
                                              
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <TextField
                                          label={entry2?.field_name}
                                          variant="outlined"
                                          value={entry2?.field_response}
                                          margin="normal"
                                          disabled={!isVerificationsEdit?.["house_verifications"]?.[entry?.item_number]}
                                          onChange={(event) => handleChangeVerification(event, "house_verifications", entry?.item_number, entry2?.field_label)}
                                          sx={{ width: "400px" }}
                                        />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    )}
                    {selectedTab2 === 2 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Verification
                        </Typography>
                        {verificationDetails?.bank_verifications?.map(
                          (entry, index) => (
                            <Box key={index} sx={{ marginBottom: "20px" }}>
                              <div style={{display: "flex"}}>
                              <Typography
                                sx={{
                                  marginLeft: "48px",
                                  fontSize: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {entry?.item}
                                </span>
                              </Typography>
                              <Tooltip title="Edit"><Button onClick={(event) => handleEditVerification(event, "bank_verifications", entry?.item_number)}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                              {isVerificationsEdit?.["bank_verifications"]?.[entry?.item_number] && <Tooltip title="Save"><Button onClick={(event) => handleClickSaveVerificationItem(event, "bank_verifications", entry?.item_number)}><SaveIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>}
                              </div>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginLeft: "22px",
                                  alignItems: "center",
                                }}
                              >
                                {entry?.verification_response?.map(
                                  (entry2, index2) => (
                                    <Box
                                      key={index2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {entry2?.field_type === "photo" ? (
                                        <>
                                          <Typography>
                                            {entry2?.field_name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              marginTop: "20px",
                                              marginLeft: "80px",
                                            }}
                                          >
                                            <div style={{display: "flex"}}>
                                              <ImageViewer
                                                imageSrc={entry2?.field_response}
                                              />
                                              {isVerificationsEdit?.["bank_verifications"]?.[entry?.item_number] && 
                                              <Tooltip title="Upload"><Button onClick={(event) => handleUploadFile(event, "bank_verifications", entry?.item_number, entry2?.field_label)}>
                                                <FileUploadIcon sx={{width: "20px", height: "20px"}}/>
                                                </Button></Tooltip>}
                                              
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <TextField
                                          label={entry2?.field_name}
                                          variant="outlined"
                                          value={entry2?.field_response}
                                          margin="normal"
                                          disabled={!isVerificationsEdit?.["bank_verifications"]?.[entry?.item_number]}
                                          onChange={(event) => handleChangeVerification(event, "bank_verifications", entry?.item_number, entry2?.field_label)}
                                          sx={{ width: "225px" }}
                                        />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    )}
                    {selectedTab2 === 3 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Verification
                        </Typography>
                        {verificationDetails?.co_applicant_verifications?.map(
                          (entry, index) => (
                            <Box key={index} sx={{ marginBottom: "20px" }}>
                              <div style={{display: "flex"}}>
                              <Typography
                                sx={{
                                  marginLeft: "48px",
                                  fontSize: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {entry?.item}
                                </span>
                              </Typography>
                              <Tooltip title="Edit"><Button onClick={(event) => handleEditVerification(event, "co_applicant_verifications", entry?.item_number)}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                              {isVerificationsEdit?.["co_applicant_verifications"]?.[entry?.item_number] && <Tooltip title="Save"><Button onClick={(event) => handleClickSaveVerificationItem(event, "co_applicant_verifications", entry?.item_number)}><SaveIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>}
                              </div>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginLeft: "52px",
                                  alignItems: "center",
                                }}
                              >
                                {entry?.verification_response?.map(
                                  (entry2, index2) => (
                                    <Box
                                      key={index2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {entry2?.field_type === "photo" ? (
                                        <>
                                          <Typography>
                                            {entry2?.field_name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              marginTop: "20px",
                                              marginLeft: "80px",
                                            }}
                                          >
                                            <div style={{display: "flex"}}>
                                              <ImageViewer
                                                imageSrc={entry2?.field_response}
                                              />
                                              {isVerificationsEdit?.["co_applicant_verifications"]?.[entry?.item_number] && 
                                              <Tooltip title="Upload"><Button onClick={(event) => handleUploadFile(event, "co_applicant_verifications", entry?.item_number, entry2?.field_label)}>
                                                <FileUploadIcon sx={{width: "20px", height: "20px"}}/>
                                                </Button></Tooltip>}
                                              
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <TextField
                                          label={entry2?.field_name}
                                          variant="outlined"
                                          value={entry2?.field_response}
                                          margin="normal"
                                          disabled={!isVerificationsEdit?.["co_applicant_verifications"]?.[entry?.item_number]}
                                          onChange={(event) => handleChangeVerification(event, "co_applicant_verifications", entry?.item_number, entry2?.field_label)}
                                          sx={{ width: "200px" }}
                                        />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    )}
                    {selectedTab2 === 4 && (
                      <>
                        <Typography
                          sx={{ marginRight: "20px", fontSize: "20px" }}
                        >
                          Verification
                        </Typography>
                        {verificationDetails?.family_verifications?.map(
                          (entry, index) => (
                            <Box key={index} sx={{ marginBottom: "20px" }}>
                              <div style={{display: "flex"}}>
                              <Typography
                                sx={{
                                  marginLeft: "48px",
                                  fontSize: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {entry?.item}
                                </span>
                              </Typography>
                              <Tooltip title="Edit"><Button onClick={(event) => handleEditVerification(event, "family_verifications", entry?.item_number)}><EditIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>
                              {isVerificationsEdit?.["family_verifications"]?.[entry?.item_number] && <Tooltip title="Save"><Button onClick={(event) => handleClickSaveVerificationItem(event, "family_verifications", entry?.item_number)}><SaveIcon sx={{width: "20px", height: "20px"}}/></Button></Tooltip>}
                              </div>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginLeft: "52px",
                                  alignItems: "center",
                                }}
                              >
                                {entry?.verification_response?.map(
                                  (entry2, index2) => (
                                    <Box
                                      key={index2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {entry2?.field_type === "photo" ? (
                                        <>
                                          <Typography>
                                            {entry2?.field_name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              marginTop: "20px",
                                              marginLeft: "80px",
                                            }}
                                          >
                                            <div style={{display: "flex"}}>
                                              <ImageViewer
                                                imageSrc={entry2?.field_response}
                                              />
                                              {isVerificationsEdit?.["family_verifications"]?.[entry?.item_number] && 
                                              <Tooltip title="Upload"><Button onClick={(event) => handleUploadFile(event, "family_verifications", entry?.item_number, entry2?.field_label)}>
                                                <FileUploadIcon sx={{width: "20px", height: "20px"}}/>
                                                </Button></Tooltip>}
                                              
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <TextField
                                          label={entry2?.field_name}
                                          variant="outlined"
                                          value={entry2?.field_response}
                                          margin="normal"
                                          disabled={!isVerificationsEdit?.["family_verifications"]?.[entry?.item_number]}
                                          onChange={(event) => handleChangeVerification(event, "family_verifications", entry?.item_number, entry2?.field_label)}
                                          sx={{ width: "200px" }}
                                        />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ display: "flex", paddingLeft: "20px" }}
                >
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "20px",
                      marginRight: "40px",
                      width: "1200px", // Set desired width
                      height: "150px", // Set desired height
                      borderLeft: "4px solid blue",
                    }}
                  >
                    <Typography variant="h6">Remarks</Typography>
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) => handleRemarksChange(e)}
                      sx={{ width: "800px", height: "70px" }} // Specific dimensions
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "auto",
                      marginBottom: "50px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={openAcceptDialog}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#C87619",
                        color: "white",
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={openDeclineDialog}
                      style={{
                        marginRight: "40px",
                        borderColor: "orange",
                        color: "#C87619",
                      }}
                    >
                      Decline
                    </Button>
                  </Box>
                </Grid>
                <Dialog
                  open={isAcceptOpen}
                  onClose={handleCloseAccept}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to accept?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={handleAcceptClick}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#C87619",
                        color: "white",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleCloseAccept}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        borderColor: "orange",
                        color: "#C87619",
                      }}
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={isDeclineOpen}
                  onClose={handleCloseDecline}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to decline?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={handleDeclineClick}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#C87619",
                        color: "white",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleCloseDecline}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        borderColor: "orange",
                        color: "#C87619",
                      }}
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  marginLeft: "500px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            ;
          </Grid>
        </Grid>
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ErrorSnackbar
        open={loanDetailsByIdFail || loanVerificationFail}
        onClose={closeSnackbar}
      />
    </>
  );
};

export default PendingActions;
